@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';

/*
	Phantom by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

/* Tiles */

	.tiles {
		body.is-preload & {
			article {
				@include vendor('transform', 'none');
				opacity: 1;
			}
		}
	}
